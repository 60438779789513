export const HOME = "/"
export const TEAM = "/team"
export const GALLERY = "/gallery" 
export const PROMETHEAN = "/event"
export const REGISTER = "register"
export const BLOGS ="/Blogs"
export const BLOG_ONE  ="/one"
export const BLOG_TWO  ="/two"
export const BLOG_THREE  ="/three"
export const BLOG_FOUR  ="/four"
export const BLOG_FIVE  ="/five"
export const BLOG_SIX  ="/six"
export const BLOG_SEVEN  ="/seven"
export const BLOG_EIGHT  ="/eight"
export const BLOG_NINE  ="/nine"
export const BLOG_TEN  ="/ten"
export const BLOG_ELEVEN  ="/eleven"
export const BLOG_TWELVE  ="/twelve"
export const BLOG_THIRTEEN  ="/thirteen"
export const BLOG_FOURTEEN  ="/fourteen"
export const BLOG_FIFTEEN  ="/fifteen"
export const BLOG_SIXTEEN  ="/sixteen"
export const BLOG_SEVENTEEN  ="/seventeen"
export const MATRIX = '/matrix'
