const ImageList = [
  {
    id: 1,
    Image: 'Image/carbon footprint and greenhouse effect.jpg',
  },

  {
    id: 2,
    Image: 'Image/soil_degradation_due_to_industrial_and_agricultural_activities.jpg',
    },

  {
    id: 3,
    Image: 'Image/oils_spills.jpg',
  },
  
  {
    id: 4,
    Image: 'Image/GRP-6 -Solution for effects of plastic pollution on marine e.png',
  },

  {
    id: 5,
    Image: 'Image/Group 8-Technical Strategies to reduce Deforestation .png',
  },
  
];

export default ImageList;
